import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './footer.scss';

const Footer = () => {
    return (
        <>
            <section className="main-footer ">
                <div className="containe-fluid">
                    <div className='row '>
                        <div className='col-xl-11 col-lg-12  col-sm-11   mx-auto main_fotrdiv'>
                            <div className="row my-footer-border">
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  col-12  text_center">
                                    <div className="inner-logo">
                                        <a className="" href="/">
                                            <img src="\DNS-logo.svg" className="img-fluid w200 mb-4" alt="" />
                                        </a>
                                        <h6 className=' dignissimos '>Join our community</h6>
                                        <ul className='list-inline ptb20'>
                                            <li className='list-inline-item'>
                                                <a href='https://t.me/+22HRKMNq2rJjZjc8' target='_blank' >
                                                    <img src="\telegramfooter.svg" className="img-fluid w28" alt="" />
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a href='https://twitter.com/HydtProtocol' target='_blank'  >
                                                    <img src="\twitter.svg" className="img-fluid w28" alt="" />
                                                </a>
                                            </li>
                                            <li className='list-inline-item'>
                                                <a href='https://discord.gg/tGPGBCYGyy' target='_blank'>
                                                    <img src="\twitterssss.svg" className="img-fluid w28" alt="" />
                                                </a>
                                            </li>
                                            {/* <li className='list-inline-item'>
                                                <a className=''>
                                                    <img src="\twitternnnn.svg" className="img-fluid w28" alt="" />
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 padng_left'>
                                <div className='padng_left '>


                                <h4 className='headdiinnnggssss'>Quick Links</h4>
                                    <ul>
                                        <li>
                                            <div className=''>       <a href="https://app.hydtprotocol.com/HYDT/dashboard" target='_blank'>
                                                <a className='white footerhead'>App</a>

                                            </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=''>
                                            <a href="https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/" target='_blank'>
                                                    <a className='white footerhead'>Docs</a>

                                                </a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=''>
                                                <a href="https://www.cyberscope.io/audits/hydt" target='_blank'>
                                                    <a className='white footerhead'>Audit</a>

                                                </a>
                                            </div>  </li>
                                        <li>
                                            <div className=''>
                                                <a href="https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/contact-support" target='_blank'>
                                                    <a className='white footerhead'>Support</a>

                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6 padng_left'>


                                <div className='padng_left '>
                                    <h4 className='headdiinnnggssss'>Docs</h4>
                                    <ul>
                                        <li>
                                            <div className=''>
                                                <a href='https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/products/stake-hydt' target='_blank' className='yoyonewwwwwww'>Staking</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=''>
                                                <a href='https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/affiliate-program/affiliate-program' target='_blank' className='yoyonewwwwwww'>Affiliate Program</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=''>
                                                <a href='https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/how-it-works' target='_blank' className='yoyonewwwwwww'>How it works</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=''>
                                                <a href='https://hydtprotocol-documents.gitbook.io/hydt-protocol-document/media-kit/media-kit' target='_blank' className='yoyonewwwwwww'>Media Kit</a>
                                            </div>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12  col-12 padd-sm">
                                    <div className="inner-icon ">
                                        <h4 className='headdiinnnggssss'>Sign up to get the latest news.</h4>
                                        <p className='yoyonewwwwwwww '>E-Mail Address </p>
                                        <ul>
                                            <li>
                                                <div className="input-group-append picture">
                                                    <input type="text" className="form-control" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                                <div className="input-group-append picture">
                                                    <button className='lastfotrbtn'>

                                                        Send
                                                    </button>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="rights ">
                <div className='containe-fluid'>
                    <div className="row">
                        <div className="col-xl-11 col-lg-12 col-12   m-auto  ptb20">
                            <div className='row'>
                                <div className="col-sm-6 m-auto">
                                    <p className="text-white small cccopyrighttttts foterfst ">© 2023 HYDT Protocol. All rights reserved.</p>
                                </div>
                                <div className="col-sm-6 m-auto ">
                                    <p className="text-white small cccopyrighttttts fotersec">     <li className='list-inline-item'>
                                    Back to Top  <a className=''>
                                            <img onClick={() => window.scrollTo(0,0)} style={{cursor: "pointer"}} src="\fotrlogo.png" className="img-fluid w28 pl-2" alt="" />
                                        </a>
                                    </li>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </>
    )
}

export default Footer;
